.SoftwareContainer {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.SoftwareContainer .formContent {
    display: flex;
    margin-bottom: 2rem;
}

.SoftwareContainer .formContent form {
    width: 100%;
}

.SoftwareContainer .swRow:not(:last-child) {
    border-bottom: 1px solid gray;
}

.SoftwareContainer .shelfSwStatus {
    display: flex;
    align-items: center;
}

.SoftwareContainer .shelfSwStatus svg {
    margin-left: 1rem;
    cursor: pointer;
}

.SoftwareContainer .updateForm .row .FormFile {
    display: block;
}