.SelectFilter {
    position: relative;
}

.SelectFilter .empty__option {
    min-height: 40px;
}

.SelectFilter .dropdown__options {
    max-height: 500px;
    overflow-y: scroll;
}