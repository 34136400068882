@-o-keyframes fadeIt {
    0% {
        background-color: #7e76ff5c;
    }
    80% {
        background-color: #7e76ff5c;
    }
    100% {
        background-color: #FFFFFF;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #7e76ff5c;
    }
    80% {
        background-color: #7e76ff5c;
    }
    100% {
        background-color: #FFFFFF;
    }
}