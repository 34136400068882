.OverviewTable .cross {
    fill: var(--color-aside-2);
}

.OverviewTable .download-icon {
    stroke: var(--color-aside-2);
    cursor: pointer;
}

.OverviewTable .action {
    text-align: right;
}

.app__table__actions {
    padding: 1rem 1.125rem;
}

.OverviewTable td a {
    font-weight: 400;
}