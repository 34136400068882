.Shelves .action .button {
    line-height: 2;
    min-width: 6rem !important;
}

.Shelves .serialNumber {
    display: flex;
    align-items: center;
}

.Shelves .serialNumber svg {
    color: orange;
    margin-right: 8px;
    font-size: 1.3rem;
}