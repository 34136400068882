.app__filter {
    background: var(--color-light);
    border-radius: var(--radius-large);
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
    padding: 1rem 1.125rem;
    align-items: center;
}

.app__filter__item {
    flex-grow: 1;
    position: relative;
}

.app__filter__item label {
    left: 0;
    line-height: var(--element);
    padding: 0 4rem 0 1.5rem;
    position: absolute;
    top: 0;
    transition: all .3s var(--transition);
    z-index: 2;
}

.app__filter__item.mini label {
    font-size: .75rem;
    line-height: 1.1;
    opacity: .75;
    top: .4rem;
}

.app__filter__item svg {
    fill: var(--color-aside-2);
    height: 3.2rem;
    pointer-events: none;
    right: 1rem;
    position: absolute;
    top: 3px;
}

.app__filter__item.ico input[type="datetime-local"], .app__filter__item.ico input[type="email"], .app__filter__item.ico input[type="text"] {
    padding-right: 3.25rem;
}