.ShelfConfigForm {
    width: 100%;
}

.ShelfConfigForm .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.ShelfConfigForm .header-container h3 {
    margin: 0;
}

.ShelfConfigForm .header-container button {
    margin-left: 2rem;
}

.ShelfConfigForm .shelfSection {
    margin-bottom: 3rem;
}