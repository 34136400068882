.ShelfConfigItemForm {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    margin-bottom: 2rem;
}

.ShelfConfigItemForm .row {
    flex-direction: column;
    width: 100%;
    min-width: 290px;
    padding-bottom: 2rem;
}