.Products .app__table__actions .section {
    display: inline-flex;
}

.Products .app__table__actions .section input[type=file] {
    margin-right: 2rem;
}

.Products .app__table__actions {
    display: flex;
    justify-content: space-between;
}