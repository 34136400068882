.OverviewPagination li {
    display: flex;
    align-items: center;
}

.OverviewPagination li a {
    text-decoration: none;
}

.OverviewPagination {
    margin: 2rem 0 1rem;
}

.OverviewPagination ul {
    display: flex;
    flex-wrap: wrap;
    gap: .625rem;
    justify-content: center;
    list-style: none;
    margin: 0;
}

.OverviewPagination .button {
    background: var(--color-aside-2);
    border: 2px solid var(--color-aside-2);
    border-radius: var(--radius-medium);
    color: var(--color-white);
    font-size: 1.25rem;
    font-weight: 300;
    height: 3rem;
    line-height: 2rem;
    min-width: 3rem;
    padding: 0;
    transition: border .3s var(--transition) .12s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OverviewPagination li:not(.selected) .button:active,
.OverviewPagination li:not(.selected) .button:focus,
.OverviewPagination li:not(.selected) .button:hover {
    border-color: var(--color-main-3);
}

.OverviewPagination li:not(.selected) .button::before {
    background: var(--color-main-2);
    border-radius: var(--radius-medium);
}

.OverviewPagination .selected .button {
    background: var(--color-white);
    border-color: var(--color-aside-2);
    color: var(--color-base);
    cursor: default;
}