.ShelfContainer {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

div.ShelfContainer:last-child {
    margin-top: 26px;
}

.ShelfContainer .formContent {
    display: flex;
}

.ShelfContainer .formContent form {
    width: 100%;
}

.ShelfContainer .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.ShelfContainer .formActions > div button {
    margin-right: 1rem;
}

.ShelfContainer .formContent form > .customGrid,
.ShelfContainer .formContent form .grid > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    margin-bottom: 16px;
}

.ShelfContainer .formContent .colorSettings > label {
    font-size: 26px;
}

.ShelfContainer .FormCheckbox input {
    margin-top: .5rem;
}

.ShelfContainer .FormCheckbox {
    text-transform: uppercase;
    margin-bottom: auto;
}

.ShelfContainer .scheduledTask label:first-child {
    width: 12rem;
}

.ShelfContainer .scheduledTask label:nth-child(2) {
    width: 3rem;
}

.ReactModal__Overlay--after-open {
    z-index: 100;
}

.ShelfContainer .validation-error {
    color: red;
    text-align: center;
}

.ShelfContainer h4 {
    margin-top: 3rem;
}

.ShelfContainer h4, .ShelfContainer h3 {
    color: var(--color-main-2);
}

.ShelfContainer .divider {
    margin: 1rem 0;
}

.shelfLogs .ShelfContainer table thead th {
    font-weight: 500;
}

.shelfLogs .ShelfContainer table td {
    font-size: .9rem;
}


@media only screen and (max-width: 40rem) {
    .ShelfContainer .formActions {
        gap: 2rem;
        flex-direction: column;
    }
}