.Alarms {
}

.Alarms tr.new {
    -o-animation: fadeIt 3s ease-in-out;
    animation: fadeIt 3s ease-in-out;
}

@-o-keyframes fadeIt {
    0% {
        background-color: #7e76ff5c;
    }
    80% {
        background-color: #7e76ff5c;
    }
    100% {
        background-color: #FFFFFF;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #7e76ff5c;
    }
    80% {
        background-color: #7e76ff5c;
    }
    100% {
        background-color: #FFFFFF;
    }
}