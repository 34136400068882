.ProductContainer {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

div.ProductContainer:last-child {
    margin-top: 26px;
}

.ProductContainer .formContent {
    display: flex;
}

.ProductContainer .formContent form {
    width: 100%;
}

.ProductContainer .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.ProductContainer .formActions > div button {
    margin-right: 1rem;
}
